import { formatPercent, isNil, isNotNil, Maybe } from "@deltagreen/utils"
import { IconInfoCircle } from "@tabler/icons-react"
import { ReactNode } from "react"

import { InteractiveDescription } from "@/components/interactiveDescription/InteractiveDescription"
import { cn } from "@/utils/cn"

export function getPercentageTextColor(
  percentage: Maybe<number>,
  opts: { displayNoPercentageAsSuccess?: boolean; useMediumColor?: boolean } = {},
) {
  if (isNil(percentage)) {
    return opts.displayNoPercentageAsSuccess ? cn("dg-text-persian-green-500") : cn("dg-text-gray-600")
  }

  if (percentage >= 0.95) {
    return cn("dg-text-persian-green-500")
  }

  if (percentage >= 0.9 && opts.useMediumColor) {
    return cn("dg-text-amber-500")
  }

  return cn("dg-text-roman-500")
}

type PilotPhaseProps = {
  percentage?: Maybe<number>
  tooltip: ReactNode
  className?: string
  displayNoPercentageAsSuccess?: boolean
  useMediumColor?: boolean
  tooltipDisabled?: boolean
}

export function PercentageWithTooltip(props: PilotPhaseProps) {
  const { percentage, tooltip, className, displayNoPercentageAsSuccess, useMediumColor, tooltipDisabled } = props

  return (
    <InteractiveDescription
      disabled={tooltipDisabled}
      triggerAsChild
      trigger={
        <div className="dg-pr-6">
          <div
            className={cn(
              "dg-relative dg-text-sm dg-font-bold dg-text-gray-600 sm:dg-text-2xl",
              getPercentageTextColor(percentage, { displayNoPercentageAsSuccess, useMediumColor }),
              className,
            )}
          >
            {isNotNil(percentage) ? formatPercent(percentage, 1) : "--"}
            {tooltipDisabled ? null : (
              <IconInfoCircle
                size={13}
                className="dg-absolute dg-right-0 dg-top-0 -dg-translate-y-0 dg-translate-x-full dg-text-gray-600"
              />
            )}
          </div>
        </div>
      }
    >
      {tooltip}
    </InteractiveDescription>
  )
}
