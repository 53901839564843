import { ElementType, forwardRef, ReactNode } from "react"

import { cn } from "@/utils/cn"
import { PolymorphicComponentPropWithRef, PolymorphicRef } from "@/utils/types"

type Props = {
  className?: string
}

type SpacerProps<TComponent extends ElementType> = PolymorphicComponentPropWithRef<TComponent, Props>
type SpacerComponent = <TComponent extends ElementType = "span">(props: SpacerProps<TComponent>) => ReactNode | null

export const Spacer: SpacerComponent = forwardRef(
  <TComponent extends ElementType = "span">(props: SpacerProps<TComponent>, ref?: PolymorphicRef<TComponent>) => {
    const { as, className, ...rest } = props
    const Component = as ?? "span"
    return <Component ref={ref} className={cn("dg-block", className)} {...rest} />
  },
)
