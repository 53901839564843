import { IconCalendarPlus, IconChevronLeft, IconChevronRight } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useState } from "react"

import { Button, Calendar, Popover, PopoverContent, PopoverTrigger } from "@/components"

type DatePickerProps = {
  date: Date
  setDate: (date: Date) => void
  minDate: Date
  maxDate: Date
}

export function DatePicker(props: DatePickerProps) {
  const { date, setDate, minDate, maxDate } = props
  const [calendarOpen, setCalendarOpen] = useState(false)

  const canGoBack = dayjs(date).isAfter(minDate)
  const canGoForward = dayjs(date).isBefore(maxDate)

  const isToday = dayjs(date).isSame(dayjs(), "day")
  return (
    <div className="dg-flex dg-flex-row dg-items-center sm:dg-gap-6">
      <div className="dg-flex dg-flex-row dg-items-center dg-rounded-lg dg-bg-white dg-px-2 dg-py-[6px] dg-shadow-[0_0_8px_0_rgba(0,0,0,0.1)] sm:dg-gap-5 sm:dg-px-4 sm:dg-py-2">
        <div className="dg-flex dg-flex-row dg-items-center sm:dg-gap-1">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              if (canGoBack) {
                setDate(dayjs(date).subtract(1, "day").toDate())
              }
            }}
            disabled={!canGoBack}
            className="disabled:dg-bg-white"
            data-testid="dayPickerPreviousDayButton"
          >
            <IconChevronLeft className="dg-h-5 dg-w-5 dg-text-gray-900" />
          </Button>

          <div
            data-testid="dayPickerSelectedDate"
            className="dg-w-28 dg-rounded-lg dg-border dg-border-gray-300 dg-px-3 dg-py-2 dg-text-sm dg-font-medium dg-text-gray-900 sm:dg-w-36 sm:dg-px-4 sm:dg-text-lg"
          >
            {isToday ? "Dnes" : dayjs(date).format("D. M. YYYY")}
          </div>

          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              if (canGoForward) {
                setDate(dayjs(date).add(1, "day").toDate())
              }
            }}
            disabled={!canGoForward}
            className="disabled:dg-bg-white"
            data-testid="dayPickerNextDayButton"
          >
            <IconChevronRight className="dg-h-5 dg-w-5 dg-text-gray-900" />
          </Button>
        </div>
        <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
          <PopoverTrigger asChild>
            <Button variant="ghost" size="icon">
              <IconCalendarPlus className="dg-h-5 dg-w-5 dg-text-gray-600 sm:dg-h-7 sm:dg-w-7" />
            </Button>
          </PopoverTrigger>

          <PopoverContent className="dg-w-auto dg-p-0" align="start">
            <Calendar
              mode="single"
              selected={date}
              defaultMonth={date}
              onSelect={(value) => {
                if (!value) {
                  return
                }

                setDate(value)
                setCalendarOpen(false)
              }}
              disabled={(date) => dayjs(date).isBefore(minDate) || dayjs(date).isAfter(maxDate)}
            />
          </PopoverContent>
        </Popover>
      </div>
      {!isToday && (
        <>
          <Button
            className="dg-hidden dg-text-gray-600 dg-underline sm:dg-block"
            variant="ghost"
            onClick={() => {
              setDate(new Date())
            }}
          >
            Zpět na dnešek
          </Button>
          <Button
            className="dg-block dg-text-gray-600 dg-underline sm:dg-hidden"
            variant="ghost"
            onClick={() => {
              setDate(new Date())
            }}
          >
            dnes
          </Button>
        </>
      )}
    </div>
  )
}
