"use client"

import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"
import { cs } from "date-fns/locale"
import * as React from "react"
import { DayPicker } from "react-day-picker"

import { cn } from "@/utils/cn"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      locale={cs}
      weekStartsOn={1}
      showOutsideDays={showOutsideDays}
      className={cn("dg-p-3", className)}
      classNames={{
        caption_label: cn("dg-text-sm dg-font-medium dg-text-gray-700"),
        months: cn("dg-relative dg-flex dg-flex-col dg-space-y-4 dg-capitalize sm:dg-flex-row sm:dg-space-y-0"),
        month: cn("dg-space-y-4 dg-capitalize"),
        month_caption: cn("dg-flex dg-items-center dg-justify-center dg-pt-1"),
        nav: cn("dg-flex dg-items-start"),
        button_previous: cn(
          "dg-absolute dg-left-1 dg-flex dg-h-7 dg-w-7 dg-items-center dg-justify-center dg-rounded-md dg-border-none dg-bg-transparent dg-p-0 dg-text-gray-700 hover:dg-bg-gray-100 hover:dg-opacity-100 disabled:dg-bg-white disabled:dg-opacity-40",
        ),
        button_next: cn(
          "dg-absolute dg-right-1 dg-flex dg-h-7 dg-w-7 dg-items-center dg-justify-center dg-rounded-md dg-border-none dg-bg-transparent dg-p-0 dg-text-gray-700 hover:dg-bg-gray-100 hover:dg-opacity-100 disabled:dg-bg-white disabled:dg-opacity-40",
        ),
        month_grid: cn("dg-w-full dg-border-collapse dg-gap-0"),
        weekdays: cn("dg-flex"),
        weekday: cn("dg-w-9 dg-rounded-md dg-text-[0.8rem] dg-font-normal dg-text-gray-500"),
        week: cn("dg-mt-2 dg-flex dg-w-full"),
        day: cn(
          "dg-relative dg-h-9 dg-w-9 dg-rounded-md dg-p-0 dg-text-center dg-text-[12.8px] focus-within:dg-relative focus-within:dg-z-20 hover:dg-bg-gray-100 [&:has([aria-selected])]:dg-bg-slate-100 first:[&:has([aria-selected])]:dg-rounded-l-md last:[&:has([aria-selected])]:dg-rounded-r-md dark:[&:has([aria-selected])]:dg-bg-slate-800 [&:has([aria-selected].day-outside)]:dg-bg-slate-100/50 dark:[&:has([aria-selected].day-outside)]:dg-bg-slate-800/50 [&:has([aria-selected].day-range-end)]:dg-rounded-r-md",
        ),
        day_button: cn("dg-h-9 dg-w-9 dg-rounded-md dg-p-0 dg-font-normal"),
        selected: cn("dg-bg-persian-green-600 dg-text-white hover:dg-bg-persian-green-600"),
        outside: cn(
          "dg-opacity-70 aria-selected:dg-bg-slate-100/50 aria-selected:dg-text-gray-500 aria-selected:dg-opacity-30",
        ),
        disabled: cn("dg-text-gray-500 dg-opacity-50 hover:dg-bg-transparent dark:dg-text-gray-400"),
        range_middle: cn("dg-aria-selected:dg-bg-slate-100 aria-selected:dg-text-gray-900"),
        hidden: cn("dg-invisible"),
        ...classNames,
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === "left") {
            return <IconChevronLeft {...props} className="dg-h-4 dg-w-4 dg-border-none" />
          }

          return <IconChevronRight {...props} className="dg-h-4 dg-w-4 dg-border-none" />
        },
      }}
      {...props}
    />
  )
}

Calendar.displayName = "Calendar"

export { Calendar }
