"use client"

import { type DialogProps } from "@radix-ui/react-dialog"
import { Command as CommandPrimitive } from "cmdk"
import * as React from "react"

import { cn } from "@/utils/cn"

import { Dialog, DialogContent } from "../dialog/Dialog"

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn("dg-flex dg-h-full dg-w-full dg-flex-col dg-overflow-hidden dg-rounded-md", className)}
    {...props}
  />
))
Command.displayName = CommandPrimitive.displayName

const CommandDialog = ({ children, ...props }: DialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent className="dg-overflow-hidden dg-p-0 dg-shadow-lg">
        <Command className="[&_[cmdk-group-heading]]:dg-px-2 [&_[cmdk-group-heading]]:dg-font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:dg-pt-0 [&_[cmdk-group]]:dg-px-2 [&_[cmdk-input-wrapper]_svg]:dg-h-5 [&_[cmdk-input-wrapper]_svg]:dg-w-5 [&_[cmdk-input]]:dg-h-12 [&_[cmdk-item]]:dg-px-2 [&_[cmdk-item]]:dg-py-3 [&_[cmdk-item]_svg]:dg-h-5 [&_[cmdk-item]_svg]:dg-w-5">
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  )
}

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Input
    ref={ref}
    className={cn(
      "dg-h-14 dg-w-full dg-rounded-lg dg-border dg-border-gray-200 dg-bg-white dg-px-4 dg-py-2 dg-text-sm dg-text-gray-600 file:dg-border-0 file:dg-bg-transparent file:dg-text-sm file:dg-font-medium placeholder:dg-text-sm placeholder:dg-text-gray-400 focus-visible:dg-border-persian-green-600 focus-visible:dg-outline-none focus-visible:dg-ring-gray-950 focus-visible:dg-ring-offset-2 disabled:dg-cursor-not-allowed disabled:dg-opacity-50",
      className,
    )}
    {...props}
  />
))

CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn("dg-max-h-[300px] dg-overflow-y-auto dg-overflow-x-hidden", className)}
    {...props}
  />
))

CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => <CommandPrimitive.Empty ref={ref} className="dg-py-6 dg-text-center dg-text-sm" {...props} />)

CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      "dg-overflow-hidden dg-p-1 [&_[cmdk-group-heading]]:dg-px-2 [&_[cmdk-group-heading]]:dg-py-1.5 [&_[cmdk-group-heading]]:dg-text-xs [&_[cmdk-group-heading]]:dg-font-medium",
      className,
    )}
    {...props}
  />
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator ref={ref} className={cn("-dg-mx-1 dg-h-px", className)} {...props} />
))
CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      "dg-relative dg-flex dg-cursor-pointer dg-select-none dg-items-center dg-rounded-sm dg-px-2 dg-py-1.5 dg-text-sm dg-outline-none data-[disabled=true]:dg-pointer-events-none data-[selected='true']:dg-bg-gray-100 data-[disabled=true]:dg-opacity-50",
      className,
    )}
    {...props}
  />
))

CommandItem.displayName = CommandPrimitive.Item.displayName

const CommandShortcut = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span className={cn("dg-ml-auto dg-text-xs dg-tracking-widest", className)} {...props} />
}
CommandShortcut.displayName = "CommandShortcut"

const CommandLoading = CommandPrimitive.Loading

export {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
  CommandSeparator,
  CommandShortcut,
}
