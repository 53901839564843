import { type ClassValue, clsx } from "clsx"
import { extendTailwindMerge } from "tailwind-merge"

const twMerge = extendTailwindMerge({
  // NOTE: more info here https://github.com/dcastil/tailwind-merge/issues/412
  experimentalParseClassName({ className, parseClassName }) {
    const parsed = parseClassName(className)
    return { ...parsed, baseClassName: parsed.baseClassName.replace("dg-", "") }
  },
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
