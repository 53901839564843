import { Maybe } from "@deltagreen/utils"
import { useState } from "react"

import { cn } from "@/utils/cn"

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from "../command/Command"
import { Popover, PopoverAnchor, PopoverContent } from "../popover/Popover"
import { Spinner } from "../spinner/Spinner"

type Props<T> = {
  onSelectedValueChange: (value: T | null) => void
  selectedKey: string
  searchValue: string
  onSearchValueChange: (value: string) => void
  items: { value: T; key: string; label: string; secondaryLabel: Maybe<string> }[]
  isLoading?: boolean
  emptyMessage?: string
  placeholder?: string
  className?: string
  label?: string
  onFocus?: () => void
  onBlur?: () => void
}

export function Autocomplete<T>({
  onSelectedValueChange,
  searchValue,
  onSearchValueChange,
  items,
  isLoading,
  emptyMessage,
  className,
  label,
  onBlur,
  onFocus,
}: Props<T>) {
  const [open, setOpen] = useState(false)

  const onSelectItem = (inputKey: string) => {
    const inputValue = items.find((item) => item.key === inputKey)?.value
    if (!inputValue) return

    onSelectedValueChange(inputValue)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Command shouldFilter={false} label={label}>
        <PopoverAnchor asChild>
          <CommandInput
            className={className}
            value={searchValue}
            onValueChange={onSearchValueChange}
            onKeyDown={(e) => setOpen(e.key !== "Escape")}
            onMouseDown={() => setOpen((open) => !!searchValue || !open)}
            onFocus={() => {
              onFocus?.()
              setOpen(true)
            }}
            onBlur={onBlur}
          />
        </PopoverAnchor>
        {!open && <CommandList aria-hidden="true" className="dg-hidden" />}
        <PopoverContent
          asChild
          onOpenAutoFocus={(e) => e.preventDefault()}
          onInteractOutside={(e) => {
            if (e.target instanceof Element && e.target.hasAttribute("cmdk-input")) {
              e.preventDefault()
            }
          }}
          className={cn("dg-w-[--radix-popover-trigger-width] dg-p-0", !searchValue && "dg-hidden")}
        >
          <CommandList>
            {isLoading && (
              <CommandLoading>
                <div className="dg-flex dg-flex-row dg-items-center dg-justify-center dg-p-4">
                  <Spinner size="xs" />
                </div>
              </CommandLoading>
            )}
            {items.length > 0 && !isLoading && (
              <CommandGroup>
                {items.map((option) => (
                  <CommandItem
                    key={option.key}
                    value={option.key}
                    onMouseDown={(e) => e.preventDefault()}
                    onSelect={onSelectItem}
                  >
                    <div className="dg-flex dg-flex-col">
                      <span>{option.label}</span>
                      {option.secondaryLabel && (
                        <span className="dg-text-xs dg-text-gray-500">{option.secondaryLabel}</span>
                      )}
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            )}
            {!isLoading && emptyMessage && searchValue && <CommandEmpty>{emptyMessage}</CommandEmpty>}
          </CommandList>
        </PopoverContent>
      </Command>
    </Popover>
  )
}
