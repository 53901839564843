"use client"

import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { IconCheck } from "@tabler/icons-react"
import { forwardRef } from "react"

import { cn } from "@/utils/cn"

const Checkbox = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "dg-peer dg-h-5 dg-w-5 dg-shrink-0 dg-rounded-sm dg-border-2 dg-border-gray-500 dg-ring-offset-white focus-visible:dg-outline-none focus-visible:dg-ring-2 focus-visible:dg-ring-slate-950 focus-visible:dg-ring-offset-2 disabled:dg-cursor-not-allowed disabled:dg-opacity-50 data-[state=checked]:dg-border-none data-[state=checked]:dg-bg-persian-green-500 data-[state=checked]:dg-text-slate-50",
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn("dg-flex dg-items-center dg-justify-center dg-text-current")}>
      <IconCheck className="dg-h-5 dg-w-5 dg-stroke-[3px]" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
