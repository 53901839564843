"use client"

import { useCallback, useState } from "react"

interface DisclosureControls {
  open: () => void
  close: () => void
  toggle: () => void
}

export function useDisclosure(initialState: boolean = false): [boolean, DisclosureControls] {
  const [opened, setOpened] = useState(initialState)

  const open = useCallback(() => setOpened(true), [])
  const close = useCallback(() => setOpened(false), [])
  const toggle = useCallback(() => setOpened((prev) => !prev), [])

  return [opened, { open, close, toggle }]
}
