"use client"

import { tv, type VariantProps } from "tailwind-variants"

const spinnerVariants = tv({
  base: "dg-animate-spin dg-rounded-full",
  variants: {
    size: {
      xs: "dg-h-5 dg-w-5 dg-border-[3px]",
      sm: "dg-h-8 dg-w-8 dg-border-4",
      md: "dg-h-10 dg-w-10 dg-border-[5px]",
    },
    color: {
      gray: "dg-border-gray-200 dg-border-t-gray-400",
      green: "dg-border-nano-200 dg-border-t-nano-600",
    },
  },
  defaultVariants: {
    size: "md",
    color: "green",
  },
})

type Props = {
  className?: string
  testId?: string
} & VariantProps<typeof spinnerVariants>

export function Spinner(props: Props) {
  const { className, size, color, testId } = props
  return (
    <div
      className={spinnerVariants({ size, color, className })}
      // TODO: delete testid fallback
      data-testid={testId ?? "spinner"}
    >
      <span className="dg-sr-only">Loading...</span>
    </div>
  )
}
