import { Heading } from "@/components/heading/Heading"
import { Spacer } from "@/components/spacer/Spacer"
import { cn } from "@/utils/cn"

import MaintenanceIcon from "../../icons/MaintenanceIcon.svg?react"

type Props = {
  className?: string
}

export function Maintenance(props: Props) {
  const { className } = props

  return (
    <div className={cn("dg-flex dg-min-h-svh dg-flex-col dg-justify-center dg-px-4", className)}>
      <MaintenanceIcon className="dg-max-h-80" />

      <Spacer className="dg-h-16" />

      <Heading size="h3" as="h1" variant="secondary" className="dg-text-center">
        Na aplikaci právě pracujeme
      </Heading>

      <Spacer className="dg-h-5" />

      <p className="dg-text-center dg-text-xs dg-text-gray-500 sm:dg-text-sm">
        Právě teď přidáváme do aplikace nejnovější vychytávku, případně usilovně spravujeme něco, co se nepovedlo.
        Zkuste to prosím později.
      </p>
    </div>
  )
}
