import { IconInfoCircle } from "@tabler/icons-react"
import { PropsWithChildren, ReactNode, useRef } from "react"
import { useClickAway } from "react-use"

import { useDisclosure, useIsDesktop } from "@/hooks"
import { cn } from "@/utils/cn"

import { Tooltip, TooltipContent, TooltipTrigger } from "../tooltip/Tooltip"

export function WithTooltip(props: PropsWithChildren<{ label: ReactNode; showOnMobile?: boolean }>) {
  const [opened, { close, open, toggle }] = useDisclosure(false)
  const showOnMobile = props.showOnMobile ?? false
  const isDesktop = useIsDesktop()

  const ref = useRef(null)
  useClickAway(ref, () => {
    if (!isDesktop) {
      close()
    }
  })

  return (
    <div className="dg-flex dg-flex-row dg-items-center">
      {props.label}
      <Tooltip open={opened} delayDuration={0}>
        <TooltipTrigger asChild className={cn(showOnMobile ? "" : "dg-hidden sm:dg-block")}>
          <button
            type="button"
            className="dg-mb-2 dg-ms-1 dg-cursor-help sm:dg-mb-3 sm:dg-ms-2"
            onMouseEnter={isDesktop ? open : undefined}
            onMouseLeave={isDesktop ? close : undefined}
            onClick={toggle}
          >
            <IconInfoCircle size={13} className="dg-text-gray-600" />
          </button>
        </TooltipTrigger>

        <TooltipContent
          ref={ref}
          className="dg-mx-4 dg-w-80 dg-rounded-lg dg-border dg-border-gray-200 dg-bg-white dg-px-4 dg-py-3 dg-text-left sm:dg-rounded-[10px]"
        >
          <p className="dg-text-xs/5 dg-font-normal dg-text-gray-600 sm:dg-text-sm">{props.children}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}
