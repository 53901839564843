"use client"

import * as DialogPrimitive from "@radix-ui/react-dialog"
import { IconX } from "@tabler/icons-react"
import * as React from "react"

import { cn } from "@/utils/cn"

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "dg-fixed dg-inset-0 dg-z-50 dg-bg-white/80 dg-backdrop-blur-sm data-[state=open]:dg-animate-in data-[state=closed]:dg-animate-out data-[state=closed]:dg-fade-out-0 data-[state=open]:dg-fade-in-0 dark:dg-bg-slate-950/80",
      className,
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />

    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "dg-fixed dg-left-1/2 dg-top-1/2 dg-z-50 dg-grid dg-max-h-[calc(100svh)] dg-w-[calc(100%-20px)] dg-max-w-3xl -dg-translate-x-1/2 -dg-translate-y-1/2 dg-gap-4 dg-overflow-y-auto dg-border dg-border-slate-200 dg-bg-white dg-p-6 dg-shadow-lg dg-duration-200 data-[state=open]:dg-animate-in data-[state=closed]:dg-animate-out data-[state=closed]:dg-fade-out-0 data-[state=open]:dg-fade-in-0 data-[state=closed]:dg-zoom-out-95 data-[state=open]:dg-zoom-in-95 data-[state=closed]:dg-slide-out-to-left-1/2 data-[state=closed]:dg-slide-out-to-top-[48%] data-[state=open]:dg-slide-in-from-left-1/2 data-[state=open]:dg-slide-in-from-top-[48%] sm:dg-w-full sm:dg-rounded-lg dark:dg-border-slate-800 dark:dg-bg-slate-950",
        className,
      )}
      {...props}
    >
      {children}

      <DialogPrimitive.Close className="dg-absolute dg-right-4 dg-top-4 dg-rounded-sm dg-opacity-70 dg-ring-offset-white dg-transition-opacity hover:dg-opacity-100 focus:dg-outline-none focus:dg-ring-2 focus:dg-ring-slate-950 focus:dg-ring-offset-2 disabled:dg-pointer-events-none data-[state=open]:dg-bg-slate-100 data-[state=open]:dg-text-slate-500 dark:dg-ring-offset-slate-950 dark:focus:dg-ring-slate-300 dark:data-[state=open]:dg-bg-slate-800 dark:data-[state=open]:dg-text-slate-400">
        <IconX className="dg-h-4 dg-w-4" />

        <span className="dg-sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("dg-flex dg-flex-col dg-space-y-1.5 dg-text-center sm:dg-text-left", className)} {...props} />
)

DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn("dg-flex dg-flex-col-reverse sm:dg-flex-row sm:dg-justify-end sm:dg-space-x-2", className)}
    {...props}
  />
)

DialogFooter.displayName = "DialogFooter"

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn("dg-text-lg dg-font-semibold dg-leading-none dg-tracking-tight", className)}
    {...props}
  />
))

DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("dg-text-sm dg-text-slate-500 dark:dg-text-slate-400", className)}
    {...props}
  />
))

DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
}
